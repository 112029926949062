<template>
  <v-card>
    <v-card-title class="blue-grey lighten-4">
      <v-row no-gutters>
        <v-col :cols="10">
          <div class="title text-truncate" style="width: 100%;">{{ currentProject.projectName }}</div>
        </v-col>
        <v-col align-self="center">
          <v-icon color="blue" large>apartment</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <Label label="Site" :value="currentProject.siteName" single-line />
      <Label
        label="Date Range"
        :value="currentProject.startDate + ' - ' + currentProject.endDate"
        single-line
      />
      <Label label="Description" :value="currentProject.description" single-line />
      <Label label="Associated Model" :value="currentProject.modelName" single-line />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn icon :to="`/analytics/mandv/project/${ this.currentProject.id }/impact`">
        <v-icon>insert_chart</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <can I="delete" a="analytics">
        <v-btn icon :to="`/analytics/mandv/projects/${ this.currentProject.id }`">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteProject">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </can>
    </v-card-actions>
  </v-card>
</template>

<script>
import Label from "@/modules/controls/_components/Label";

export default {
  components: {
    Label
  },
  props: {
    analyticProject: null
  },
  data() {
    return {
      currentProject: this.analyticProject
    };
  },
  computed: {},
  watch: {
    analyticProject: function(newVal) {
      this.currentProject = { ...newVal };
    }
  },
  methods: {
    async deleteProject() {
      this.$emit("onprojectdeletefromchild", this.currentProject);
    }
  }
};
</script>