<template>
  <section>
    <v-row>
      <v-col v-if="$can('delete', 'analytics')">
        <v-btn
          color="primary"
          :to="`/site/${this.selectedSite.siteId}/analytics/projects/create`"
        >
          <v-icon left>mdi-plus-thick</v-icon>New Project
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          :return-object="true"
          @input="filterProjects"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-btn>
            <v-icon>apps</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>list_alt</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>
    <validation-summary :messages="server_errors" />
    <confirm-dialog
      ref="confirmDelete"
      title="Delete this project?"
    ></confirm-dialog>

    <v-data-table
      :headers="headers"
      :items="mappedAnalyticProjects"
      hide-default-footer
      :show-select="$can('delete', 'analytics')"
      class="elevation-1"
      v-model="selectedMappedProjects"
      v-if="toggleView === 1 && mappedAnalyticProjects.length > 1"
    >
      <template v-slot:[`item.projectName`]="{ item }">
        <td>
          <router-link
            :to="`/analytics/mandv/projects/${item.id}`"
            >{{ item.projectName }}</router-link
          >
        </td>
      </template>

      <template v-slot:[`item.impactReportLink`]="{ item }">
        <td>
          <router-link
            :to="`/analytics/mandv/project/${item.id}/impact`"
            >{{ impactReportLink }}</router-link
          >
        </td>
      </template>

      <template v-slot:top>
        <v-row class="ml-1">
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="$can('delete', 'analytics')">
            <v-btn
              icon
              @click="handleProjectDeletesMultiple"
              :disabled="selectedMappedProjects.length === 0"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-data-iterator
      :items="mappedAnalyticProjects"
      v-if="toggleView === 0 && mappedAnalyticProjects.length > 0"
    >
      <template>
        <v-row>
          <v-col
            v-for="project in mappedAnalyticProjects"
            :key="project.id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <project-grid-item
              :analyticProject="project"
              @onprojectdeletefromchild="handleProjectDeleteFromChild"
            ></project-grid-item>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-row v-if="mappedAnalyticProjects.length === 0">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800"
      >
        No projects configured
      </v-card>
    </v-row>
  </section>
</template>

<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import api from "../../projects/_api";
import ValidationSummary from "@/components/Dialogs/ValidationSummary";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ProjectGridItem from "./ProjectGridItem";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AnalyticsProjectsModule",

  components: {
    "validation-summary": ValidationSummary,
    "confirm-dialog": ConfirmDialog,
    "project-grid-item": ProjectGridItem,
  },

  async created() {
    await this.getUserSites();
    self.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.getProjects();
      this.loading = false;
    }
  },

  mounted() {},

  data() {
    return {
      headers: [
        { text: "Project Name", value: "projectName" },
        { text: "Site Name", value: "siteName" },
        { text: "Associated Model", value: "modelName" },
        { text: "Description", value: "description" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Impact Report", value: "impactReportLink" },
      ],
      server_errors: [],

      mappedAnalyticProjects: [],

      analyticProjects: [],

      selectedMappedProjects: [],

      sites: [],
      selectedSite: {},
      toggleView: 0,
      impactReportLink: "Impact Report",
      loading: true,
    };
  },

  computed: {
    ...mapGetters({ defaultSite: "session/defaultSite" }),
  },

  methods: {
    ...mapActions({ setDefaultSite: "session/setDefaultSite" }),

    async getProjects() {
      if (this.defaultSite) {
        this.selectedSite = this.defaultSite;
        this.analyticProjects = await api.getAnalyticProjects(
          this.selectedSite.siteId
        );
        this.mappedAnalyticProjects = this.mapAnalyticProjects(
          this.analyticProjects
        );
      }

      this.loading = false;
    },

    filterProjects(site) {
      this.setDefaultSite(site);
      this.getProjects();
    },

    async getUserSites() {
      let self = this;
      self.sites = await api.getSites();
      self.getProjects();
    },

    mapAnalyticProjects(amList) {
      const self = this;
      let getMappedProject = (item) => {
        return {
          id: item.id,
          siteName: item.siteName,
          siteId: self.siteId,
          projectName: item.projectName,
          modelName: item.modelName,
          description: item.description || "N/A",
          startDate: this.formatDate(item.projectStartDateTime),
          endDate: this.formatDate(item.projectEndDateTime),
        };
      };
      var at = amList.map(getMappedProject);
      return at;
    },

    async processAnalyticProjectDelete() {
      let self = this;
      const deleteProjects = async (proj) =>
        await api.deleteAnalyticProject(proj.id);
      let deletePromises = this.selectedMappedProjects.map(deleteProjects);
      console.log(deletePromises);
      Promise.all(deletePromises)
        .then((response) => {
          console.log(response);
          self.getProjects();
        })
        .catch((error) => {
          console.log(error);
          self.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    formatDate(date) {
      return new Date(date).toISOString().substring(0, 10);
    },
    async handleProjectDeleteFromChild(project) {
      const msg =
        "Are you sure you want to delete the selected Analytics Project?  This cannot be undone.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      console.log(project);
      this.selectedMappedProjects = [project];
      this.processAnalyticProjectDelete();
    },
    async handleProjectDeletesMultiple() {
      const msg =
        "Are you sure you want to delete the selected Analytics Projects?  This cannot be undone.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.processAnalyticProjectDelete();
    },
  },
};
</script>
